import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
`
const StyledLabel = styled.label`
  margin-bottom: 0.875rem;
  font-weight: 300;
  font-size: 0.875rem;
`

const StyledSelect = styled.select<{ $border: boolean }>`
  padding: ${({ $border }) => ($border ? `1rem` : `0`)};
  border-radius: 5px;
  font-weight: 300;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('/arrow-down-thin.png');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  border: ${({ $border, theme }) =>
    $border ? `1px solid ${theme.colors.gray}` : `1px solid transparent`};
  color: ${({ theme }) => theme.colors.lightGray};
  outline: none;
  cursor: pointer;
  padding-right: 25px;
`
const StyledOption = styled.option`
  padding: 1rem;
`

interface DropdownFilterProps {
  name: string
  title?: string
  items: ItemProps[]
  value: string | number | undefined
  onChange: (e: React.FormEvent<HTMLSelectElement>) => void
  $border?: boolean
  className?: string
}

const DropdownFilter = ({
  items,
  name,
  title,
  value,
  onChange,
  $border = true,
  className
}: DropdownFilterProps) => {
  return (
    <Container className={className}>
      {title && <StyledLabel htmlFor={name}>{title}</StyledLabel>}
      <StyledSelect
        $border={$border}
        name={name}
        id={name}
        onChange={onChange}
        value={value}
      >
        {items?.map((item, index) => (
          <StyledOption key={`${index}-${item}`} value={item.value}>
            {item.label}
          </StyledOption>
        ))}
      </StyledSelect>
    </Container>
  )
}

export default DropdownFilter
