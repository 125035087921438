import { put } from 'redux-saga/effects'
import {
  UserLogin,
  getUserInfo,
  setDefaultSeason,
  updateUseRoleResort,
  updateUserResort
} from 'services'
import { Resort } from '../report/types'
import {
  changeResortFailure,
  changeResortRequest,
  changeResortSuccess,
  changeUserResortFailure,
  changeUserResortRequest,
  changeUserResortSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  logoffFailure,
  logoffSuccess,
  updateResortFailure,
  updateResortRequest,
  updateResortSuccess,
  updateUserInfoFailure,
  updateUserInfoRequest,
  updateUserInfoSuccess,
  userInfoFailure,
  userInfoSuccess
} from './actions'
import { UserInfo } from './types'

export function* userLogin({ payload }: ReturnType<typeof loginRequest>) {
  try {
    const response: UserInfo = yield UserLogin(
      payload.phoneNumber,
      payload.password
    )
    yield put(loginSuccess(response))
  } catch (err: any) {
    yield put(loginFailure(err))
  }
}

export function* userInfoSaga() {
  try {
    const response: UserInfo = yield getUserInfo()
    yield put(userInfoSuccess(response))
  } catch (err: any) {
    yield put(userInfoFailure(err))
  }
}

export function* updateUserInfoSaga({
  payload
}: ReturnType<typeof updateUserInfoRequest>) {
  try {
    yield put(updateUserInfoSuccess(payload))
  } catch (err: any) {
    yield put(updateUserInfoFailure(err))
  }
}

export function* logoffSaga() {
  try {
    yield put(logoffSuccess({} as UserInfo))
  } catch (err: any) {
    yield put(logoffFailure(err))
  }
}

export function* updateUserDataSaga({
  payload: { resortId, seasonName }
}: ReturnType<typeof updateResortRequest>) {
  try {
    const response: Resort = yield setDefaultSeason({ resortId, seasonName })
    yield put(updateResortSuccess(response))
  } catch (err: any) {
    yield put(updateResortFailure(err))
  }
}

export function* changeResortSaga({
  payload: { resortId }
}: ReturnType<typeof changeResortRequest>) {
  try {
    const response: UserInfo = yield updateUserResort(resortId)
    yield put(changeResortSuccess(response))
  } catch (err: any) {
    yield put(changeResortFailure(err))
  }
}

export function* changeUserResortSaga({
  payload: { resortId }
}: ReturnType<typeof changeUserResortRequest>) {
  try {
    const response: UserInfo = yield updateUseRoleResort(resortId)
    yield put(changeUserResortSuccess(response))
    if (response.userStatus === 'PENDING') {
      window.location.pathname = '/pending-account'
    }
  } catch (err: any) {
    yield put(changeUserResortFailure(err))
  }
}
